import { Injectable } from '@angular/core';


// for api hitting

import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpRequest,
  HttpParams,
  HttpEvent,
  HttpEventType,
  HttpResponse
} from "@angular/common/http";
import { Observable, of, throwError, Subject, BehaviorSubject } from "rxjs";
import { catchError, map, tap, timeout } from "rxjs/operators";
import { Router } from "@angular/router";
import {BASE_URL} from '../../constant';
import { FakeService } from 'src/app/service/fakeapi.service';
@Injectable({
  providedIn: 'root'
})
export class EventDetailService {
 baseUrl = BASE_URL; // live

  public serverURL = this.baseUrl + "/api/";
  constructor(private http: HttpClient,
    private fakeService: FakeService,
     private router: Router) { }


  eventDetail(data) {
    
    let request: any = data;
    // return this.fakeService.getEventDetail().pipe(// if responce takes more than one minute
    //   timeout(60000),
    //   // ...errors if any
    //   catchError(this.handleError),
    //   // ...and calling .json() on the response to return data
    //   map((res) => res)
    // );
    return this.http.get(this.serverURL + 'event', {params:request}).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map((res) => res)
    );
  }



  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error("Client Side Error :", errorResponse.error.message);
    } else {
      console.error("Server Side Error :", errorResponse);

    }
    // return an observable with a meaningful error message to the end user
    return throwError(
      errorResponse
      // "There is a problem with the service. We are notified & working on it. Please try again later."
    );
  }

}
