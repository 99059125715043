import { Injectable } from '@angular/core';

// for api hitting

import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpRequest,
  HttpParams,
  HttpEvent,
  HttpEventType,
  HttpResponse
} from "@angular/common/http";
import { Observable, of, throwError, Subject, BehaviorSubject } from "rxjs";
import { catchError, map, tap, timeout } from "rxjs/operators";
import { Router } from "@angular/router";
import {BASE_URL} from '../../../constant';
@Injectable({
  providedIn: 'root'
})
export class Step5Service {
  baseUrl = BASE_URL; // live

  public serverURL = this.baseUrl + "/api/";
  constructor(private http: HttpClient, private router: Router) { }

  editEvent(data):Observable<HttpResponse<any>> {
    
    let request: any = data;
    var session = JSON.parse(localStorage.getItem("session"));
    const headers = new HttpHeaders().set('Authorization', session.user_token.access_token) .set("Content-Type", "application/json");

    return this.http.put(this.serverURL + 'event', request, { headers }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map((res:HttpResponse<any>) => res)
    );
  }



  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error("Client Side Error :", errorResponse.error.message);
    } else {
      console.error("Server Side Error :", errorResponse);

    }
    // return an observable with a meaningful error message to the end user
    return throwError(
      errorResponse
      // "There is a problem with the service. We are notified & working on it. Please try again later."
    );
  }


}
